import { API } from '@xbto/api-client';
import {
  action,
  Action,
  ActionOn,
  actionOn,
  Computed,
  computed,
  effectOn,
  EffectOn,
  thunk,
  Thunk,
} from 'easy-peasy';
import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
import { FILTER_ASSETS_BY } from '../../config';
import { TIMERS } from '../../constants';
import { Balances, Enriched, EnrichedAccountDetailAsset } from '../../types';
import {
  createBalancesByAsset,
  factories,
  getApiErrorCode,
  getApiErrorMessage,
  getTradeSimulationError,
  searchOrFilterAsset,
} from '../../utils';
import { DataModel } from '../data-store';
import { AdditionalOptionsXHR, Injections, ThunkResult } from '../types';
import { TradeAssetGroup, TradeFormValues } from './types';
import { tradeValidations } from './validations';
import { getBuySellSidePreference } from './utils';
import { BaseModel, createBaseModel } from '../base-store';
import { getAccountsByWorkflow } from '../../hooks';

const getAssetListTitle = (v: TradeFormValues) => {
  if (!v.fromAsset && !v.toAsset) {
    return `Choose an asset to ${v.side.toLowerCase()}`;
  }

  return v.side === API.Side.Sell ? `Choose an asset to receive` : `Pay with`;
};

const getAssetListHeader = (v: TradeFormValues) => {
  if (
    (v.side === API.Side.Buy && !v.fromAsset) ||
    (v.side === API.Side.Sell && v.fromAsset)
  ) {
    return {
      name: 'Asset',
      value: 'Price / 24H Change',
    };
  }

  return {
    name: 'Asset',
    value: 'Balance',
  };
};

const getSimulationNote = (
  v: TradeFormValues,
  s: Enriched.TradeSimulation | null
) => {
  if (!s) {
    return undefined;
  }
  const asset = v.side === API.Side.Buy ? v.fromAsset : v.toAsset;
  const disclaimer = `The amount ${
    asset ? `of ${asset.currency.displayCode}` : ''
  } you get will only be determined when the trade is executed.`;
  const title =
    v.side === API.Side.Buy
      ? v.amountSide === 'fromAssetAmount'
        ? `You sell`
        : `You buy`
      : v.amountSide === 'toAssetAmount'
        ? `You sell`
        : `You buy`;
  const description =
    v.side === API.Side.Buy
      ? v.amountSide === 'fromAssetAmount'
        ? s.formatted.amountFromWithCurrencyCode
        : s.formatted.amountToWithCurrencyCode
      : v.amountSide === 'toAssetAmount'
        ? s.formatted.amountFromWithCurrencyCode
        : s.formatted.amountToWithCurrencyCode;
  const value =
    v.side === API.Side.Buy
      ? v.amountSide === 'fromAssetAmount'
        ? s.formatted.amountUsdFromWithCurrencyCode
        : s.formatted.amountUsdToWithCurrencyCode
      : v.amountSide === 'toAssetAmount'
        ? s.formatted.amountUsdFromWithCurrencyCode
        : s.formatted.amountUsdToWithCurrencyCode;
  const showValue = !(v.side === API.Side.Buy
    ? v.amountSide === 'fromAssetAmount'
      ? s.currencyFrom === 'USD'
      : s.currencyTo === 'USD'
    : v.amountSide === 'toAssetAmount'
      ? s.currencyFrom === 'USD'
      : s.currencyTo === 'USD');

  return {
    title,
    disclaimer,
    description,
    value: showValue ? value : '',
  };
};

export interface BuySellModel extends BaseModel {
  _errorCode: number | null;
  _setErrorCode: Action<BuySellModel, number>;

  resetState: Thunk<BuySellModel, undefined, Injections, DataModel>;

  canTradeFromAccount: Computed<BuySellModel, boolean, DataModel>;
  allowedCanTradeAccountsBuy: Computed<
    BuySellModel,
    Enriched.ListAccountItem[],
    DataModel
  >;
  allowedCanTradeAccountsSell: Computed<
    BuySellModel,
    Enriched.ListAccountItem[],
    DataModel
  >;

  formValues: TradeFormValues;
  setSide: Action<BuySellModel, API.Side>;
  setFormValues: Action<BuySellModel, TradeFormValues>;
  resetForm: Action<BuySellModel, Partial<TradeFormValues> | undefined>;
  sourceAssetBalances: Computed<BuySellModel, Balances | null>;
  destinationAssetBalances: Computed<BuySellModel, Balances | null>;

  labels: Computed<
    BuySellModel,
    {
      disclaimerExecutionTime: {
        title: string;
        description: string;
        // icon: enum -> Note: one day when we have unified icon library for both apps
      };
      assetListTitle: string;
      confirmTitle: string;
      confirmButtonText: string;
      rateConfirmation: string;
      slipageRisk: {
        title: string;
        description: string;
      };
      exchangeRateTitle: string;
      simulationNote:
        | {
            title: string;
            description: string;
            disclaimer: string;
            value: string;
          }
        | undefined;
      assetListHeader: {
        name: string;
        value: string;
      };
    },
    DataModel
  >;
  hasRequiredFormValues: Computed<BuySellModel, boolean, DataModel>;

  assetSearch: string;
  setAssetSearch: Action<BuySellModel, string>;
  assetFilter: FILTER_ASSETS_BY;
  setAssetFilter: Action<BuySellModel, FILTER_ASSETS_BY>;
  _assets: API.TradableAssetsResponse | null;
  _setAssets: Action<BuySellModel, API.TradableAssetsResponse | null>;
  _assetsTradable: Computed<
    BuySellModel,
    EnrichedAccountDetailAsset[],
    DataModel
  >;
  _filteredAssetsTradable: Computed<
    BuySellModel,
    EnrichedAccountDetailAsset[],
    DataModel
  >;
  _assetsNonTradable: Computed<
    BuySellModel,
    EnrichedAccountDetailAsset[],
    DataModel
  >;
  _filteredAssetsNonTradable: Computed<
    BuySellModel,
    EnrichedAccountDetailAsset[],
    DataModel
  >;
  groupedAssets: Computed<BuySellModel, TradeAssetGroup[], DataModel>;
  hasNoAssets: Computed<BuySellModel, boolean, DataModel>;
  isTradableAsset: Computed<
    BuySellModel,
    (ccyCode: string) => [boolean, EnrichedAccountDetailAsset | undefined],
    DataModel
  >;
  getAssets: Thunk<
    BuySellModel,
    {
      assetFrom?: string;
      side: API.Side;
      isBackgroundXHR?: boolean;
    },
    Injections,
    DataModel,
    Promise<void>
  >;

  isResimulating: boolean;
  setIsResimulating: Action<BuySellModel, boolean>;
  _simulation: API.TradeSimulation | null;
  simulation: Computed<
    BuySellModel,
    Enriched.TradeSimulation | null,
    DataModel
  >;
  _setSimulation: Action<BuySellModel, API.TradeSimulation | null>;
  _autoSimulate: EffectOn<BuySellModel, DataModel, Injections>;
  simulate: Thunk<
    BuySellModel,
    TradeFormValues & AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<ThunkResult<API.TradeSimulation | null>>
  >;

  trade: Enriched.Trade | null;
  _setTrade: Action<BuySellModel, Enriched.Trade | null>;
  createTrade: Thunk<
    BuySellModel,
    API.CreateTradeRequest,
    Injections,
    DataModel,
    Promise<ThunkResult<Enriched.Trade | null>>
  >;
  // actionOn
  _resetErrorCode: ActionOn<BuySellModel>;
}

const initialFormValues: TradeFormValues = {
  fromAsset: null,
  fromAmount: null,
  toAsset: null,
  toAmount: null,
  side: API.Side.Buy,
  amountSide: null,
};

export const buySellModel: BuySellModel = {
  ...createBaseModel(),

  _errorCode: null,
  _setErrorCode: action((state, payload) => {
    state._errorCode = payload;
  }),

  resetState: thunk(actions => {
    actions.setAssetFilter(FILTER_ASSETS_BY.ALL);
    actions.setAssetSearch('');
    actions.setFormValues(initialFormValues);

    actions._setSimulation(null);
    actions._setTrade(null);
    actions._setAssets(null);

    actions.setBusy(false);
    actions.setError(null);
  }),

  canTradeFromAccount: computed(
    [(_state, storeState) => storeState.portfolio.accountDetail?.assets],
    assets => {
      if (!assets) {
        return false;
      }
      return assets.some(a => {
        return a.hasBalance && a.canTrade;
      });
    }
  ),
  allowedCanTradeAccountsBuy: computed(
    [
      (_state, storeState) => storeState.portfolio.accounts,
      (_state, storeState) => storeState.portfolio.assetHoldings?.accounts,
    ],
    (portfolioAccounts, assetAccounts) =>
      getAccountsByWorkflow({
        assetAccounts,
        portfolioAccounts,
        workflowType: 'workflow-buy',
      })
  ),

  allowedCanTradeAccountsSell: computed(
    [
      (_state, storeState) => storeState.portfolio.accounts,
      (_state, storeState) => storeState.portfolio.assetHoldings?.accounts,
    ],
    (portfolioAccounts, assetAccounts) =>
      getAccountsByWorkflow({
        assetAccounts,
        portfolioAccounts,
        workflowType: 'workflow-sell',
      })
  ),

  formValues: initialFormValues,
  setSide: action((state, value) => {
    state.formValues = {
      ...state.formValues,
      side: value,
    };
  }),
  setFormValues: action((state, payload) => {
    state.formValues = payload;
  }),
  resetForm: action((state, values) => {
    state.formValues = {
      ...initialFormValues,
      ...(values ?? {}),
    };
  }),
  sourceAssetBalances: computed(
    [s => s.formValues?.fromAsset ?? null],
    asset => {
      if (!asset) {
        return null;
      }

      return createBalancesByAsset(asset);
    }
  ),
  destinationAssetBalances: computed(
    [s => s.formValues?.toAsset ?? null],
    asset => {
      if (!asset) {
        return null;
      }

      return createBalancesByAsset(asset);
    }
  ),

  labels: computed(
    [state => state.formValues, state => state.simulation],
    (values, sim) => {
      return {
        disclaimerExecutionTime: {
          title: `Trades may take up to 1 business day to be settled`,
          description: `Unlike most trading venues, all trades are physically settled in your cryptocurrency account every business day.`,
        },
        assetListTitle: getAssetListTitle(values),
        confirmButtonText: values.side,
        confirmTitle: `${values.side} ${values.fromAsset?.currency?.displayCode || ''}`,
        rateConfirmation:
          'The rate is confirmed upon trade confirmation and will be no more than 0.05% slippage on currently displayed rate.',
        slipageRisk: {
          title: `Slippage Risk`,
          description: `Slippage is the difference between the expected price of a trade and the price at which the trade is executed. We take reasonable steps so that execution of our quoted prices will match the conversion rate provided, however fast moving markets may result in execution of a transaction at a price which has ceased to be the quoted market price.`,
        },
        exchangeRateTitle: 'Indicative exchange rate',
        simulationNote: getSimulationNote(values, sim),
        assetListHeader: getAssetListHeader(values),
      };
    }
  ),
  hasRequiredFormValues: computed([state => state.formValues], values => {
    const hasRequiredValues = tradeValidations.hasRequiredValues(values);
    return hasRequiredValues;
  }),

  assetSearch: '',
  setAssetSearch: action((state, payload) => {
    state.assetSearch = payload;
  }),
  assetFilter: FILTER_ASSETS_BY.ALL,
  setAssetFilter: action((state, payload) => {
    state.assetFilter = payload;
  }),
  _assets: null,
  _setAssets: action((state, payload) => {
    state._assets = payload;
  }),
  _assetsTradable: computed(
    [
      state => state._assets,
      (_state, storeState) => storeState.metaData._fxRates,
      (_state, storeState) => storeState.portfolio.accountDetail?.assets,
    ],
    (assets, fxRates, holdings) => {
      const result =
        factories.mapTradableCurrenciesToEnrichedAccountDetailAssets(
          assets?.tradableAssets ?? [],
          fxRates ?? [],
          holdings ?? []
        );
      return result;
    }
  ),
  _filteredAssetsTradable: computed(
    [
      state => state._assetsTradable,
      state => state.assetSearch,
      state => state.assetFilter,
    ],
    (assets, search, filter) => {
      const result = assets.filter(item => {
        const match = !!searchOrFilterAsset(item, search, filter);
        return match;
      });
      return result;
    }
  ),
  _assetsNonTradable: computed(
    [
      state => state._assets,
      (_state, storeState) => storeState.metaData._fxRates,
      (_state, storeState) => storeState.portfolio.accountDetail?.assets,
    ],
    (assets, fxRates, holdings) => {
      const result =
        factories.mapTradableCurrenciesToEnrichedAccountDetailAssets(
          assets?.nonTradableAssets ?? [],
          fxRates ?? [],
          holdings ?? []
        );
      return result;
    }
  ),
  _filteredAssetsNonTradable: computed(
    [
      state => state._assetsNonTradable,
      state => state.assetSearch,
      state => state.assetFilter,
    ],
    (assets, search, filter) => {
      return assets.filter(item => {
        return searchOrFilterAsset(item, search, filter);
      });
    }
  ),
  groupedAssets: computed(
    [
      state => state._filteredAssetsTradable,
      state => state._filteredAssetsNonTradable,
      state => state.formValues,
      state => state.hasNoAssets,
      state => state.assetSearch,
    ],
    (
      filteredTradableAssets,
      filteredNonTradableAssets,
      formValues,
      hasNoAssets,
      assetSearch
    ) => {
      if (assetSearch && hasNoAssets) {
        return [];
      }
      if (!formValues.fromAsset) {
        return [
          {
            title: undefined,
            key: 'tradable',
            data: filteredTradableAssets,
          },
        ];
      }
      const isBuy = formValues.side === API.Side.Buy;
      const sideLowerCase = formValues.side.toLowerCase();
      const ccyCode =
        formValues.fromAsset.currency.displayCode ||
        formValues.fromAsset.currency.code;
      const sideKeyword = isBuy ? 'with' : 'into';
      return [
        {
          title: undefined,
          key: 'tradable',
          data: filteredTradableAssets,
        },
        {
          title: `It is not possible to ${sideLowerCase} ${ccyCode} ${sideKeyword} the assets below`,
          key: 'nonTradable',
          data: filteredNonTradableAssets,
        },
      ];
    }
  ),
  hasNoAssets: computed(
    [
      state => state._filteredAssetsTradable,
      state => state._filteredAssetsNonTradable,
    ],
    (tradableAssets, nonTradableAssets) => {
      const hasTradableAssets = tradableAssets && tradableAssets.length > 0;
      const hasNonTradableAssets =
        nonTradableAssets && nonTradableAssets.length > 0;

      return !hasTradableAssets && !hasNonTradableAssets;
    }
  ),
  isTradableAsset: computed(
    [state => state._filteredAssetsTradable],
    tradableAssets => {
      return ccyCode => {
        const hasTradableAssets = tradableAssets && tradableAssets.length > 0;
        if (!hasTradableAssets) {
          return [false, undefined];
        }
        const asset = tradableAssets.find(a => a.currency.code === ccyCode);
        if (!asset) {
          return [false, undefined];
        }
        return [true, asset];
      };
    }
  ),
  getAssets: thunk(
    async (
      actions,
      { ...payload },
      { injections, getStoreState, getState }
    ) => {
      const storeState = getStoreState();

      if (
        !(
          storeState.portfolio.accountDetail?.canTrade &&
          storeState.portfolio.accountDetail.hasBalance
        )
      ) {
        actions.setError(
          `Operation ${
            getState().formValues.side
          } is not allowed for this account.`
        );
        actions._setAssets(null);
        return;
      }

      try {
        actions.setBusy(true);
        actions._setAssets(null);

        if (!payload.assetFrom) {
          actions.setSide(payload.side);
        }

        injections.apiClient.setAdditionalHeaders({
          'x-account-id':
            storeState.portfolio.accountDetail?.account?.accountId,
        });
        const { isSuccessful, result, errorMessage } =
          await injections.apiClient.listTradableAssets({
            search: null,
            assetFrom: payload.assetFrom || null,
            side: payload.side,
          });
        if (!isSuccessful || !result) {
          const message = getApiErrorMessage(errorMessage);
          actions.setError(message);
          actions._setAssets(null);
        }
        actions._setAssets(result);
      } catch (error) {
        const message = getApiErrorMessage(error);
        actions.setError(message);
        actions._setAssets(null);
      } finally {
        actions.setBusy(false);
      }
    }
  ),

  isResimulating: false,
  setIsResimulating: action((state, payload) => {
    state.isResimulating = payload;
  }),
  _simulation: null,
  simulation: computed(
    [
      s => s._simulation,
      s => s.formValues,
      (_state, storeState) => storeState.metaData.currencies,
      (_state, storeState) => storeState.metaData.fiatCurrencyCodes,
    ],
    (_simulation, formValues, currencies, fiatCurrencyCodes) => {
      const result = factories.enrichTradeSimulation(
        _simulation,
        formValues,
        fiatCurrencyCodes,
        currencies
      );
      return result;
    }
  ),
  _setSimulation: action((state, payload) => {
    state._simulation = payload;
  }),
  _autoSimulate: effectOn(
    [
      state => state.formValues,
      state => state.hasRequiredFormValues,
      state => state.error,
    ],
    (actions, change) => {
      const [values, hasRequiredValues, error] = change.current;

      if (error) {
        console.info(`trade: error`, error);
        actions._setSimulation(null);
        return;
      }

      if (!hasRequiredValues) {
        console.info(`trade: hasRequiredValues`, hasRequiredValues);
        actions._setSimulation(null);
        return;
      }

      const { hasErrors, errors, firstError } =
        tradeValidations.checkValues(values);
      if (hasErrors) {
        console.info(`trade: formValues errors:`, errors);
        actions.setError(firstError);
        actions._setSimulation(null);
        return;
      }

      // Creates a singleton
      const getDebouncedSimulate = memoize(() =>
        debounce(actions.simulate, TIMERS.SIMULATE_DEBOUNCE)
      );

      const debouncedSimulate = getDebouncedSimulate();

      debouncedSimulate(values);
      return () => debouncedSimulate.cancel();
    }
  ),
  simulate: thunk(
    async (actions, payload, { injections, getStoreState, getState }) => {
      if (!payload.fromAmount && !payload.toAmount) {
        return {
          isSuccessful: false,
        };
      }

      const storeState = getStoreState();
      const state = getState();

      try {
        actions.setError(null);
        actions.setFormValues(payload);

        if (!state.isResimulating) {
          actions._setSimulation(null);
        }

        const isSell = payload.side === API.Side.Sell;
        const sidePreference = getBuySellSidePreference(payload);
        const body: API.SimulateTradeRequest = {
          currencyFrom: isSell
            ? (payload.fromAsset?.currency?.code as string)
            : (payload.toAsset?.currency?.code as string),
          currencyTo: isSell
            ? (payload.toAsset?.currency.code as string)
            : (payload.fromAsset?.currency.code as string),
          amountFrom: isSell ? payload.fromAmount : payload.toAmount,
          amountTo: isSell ? payload.toAmount : payload.fromAmount,
          sidePreference,
          clientId: null,
        };
        if (!payload.isBackgroundXHR) {
          actions.setBusy(true);
        }
        injections.apiClient.setAdditionalHeaders({
          'x-account-id':
            storeState.portfolio.accountDetail?.account?.accountId,
        });

        const { isSuccessful, errorMessage, result } =
          await injections.apiClient.simulateTrade(body);
        if (!isSuccessful) {
          actions.setError(errorMessage);
          if (!payload.isBackgroundXHR) {
            actions.setBusy(false);
          }
          actions._setSimulation(null);
          return {
            isSuccessful,
            errorMessage,
          };
        }

        if (!result?.canTrade) {
          const tradeSimulationError = getTradeSimulationError(result);
          actions.setError(tradeSimulationError);
          if (!payload.isBackgroundXHR) {
            actions.setBusy(false);
          }
          actions._setSimulation(result);
          return {
            isSuccessful: false,
            errorMessage: tradeSimulationError,
          };
        }

        if (!payload.isBackgroundXHR) {
          actions.setBusy(false);
        }
        actions._setSimulation(result);
        return {
          isSuccessful: true,
          result,
        };
      } catch (error) {
        const errorCode = getApiErrorCode(error);
        const message = getApiErrorMessage(error);
        if (!payload.isBackgroundXHR) {
          actions.setBusy(false);
        }
        actions._setSimulation(null); // clear previous simulation if errored to simulate
        actions.setError(message);
        return {
          isSuccessful: false,
          errorMessage: message,
          errorCode,
        };
      } finally {
        actions.setIsResimulating(false);
      }
    }
  ),

  trade: null,
  _setTrade: action((state, payload) => {
    state.trade = payload;
  }),
  createTrade: thunk(
    async (actions, payload, { injections, getStoreState }) => {
      const storeState = getStoreState();

      actions.setError(null);
      actions.setBusy(true);

      try {
        injections.apiClient.setAdditionalHeaders({
          'x-account-id':
            storeState.portfolio.accountDetail?.account?.accountId,
        });

        const { isSuccessful, errorMessage, result } =
          await injections.apiClient.createTrade(payload);
        if (!isSuccessful) {
          actions.setError(errorMessage);
          return {
            isSuccessful: false,
            errorMessage,
          };
        }

        const enrichedResult = factories.enrichTrade(
          result,
          storeState.metaData.fiatCurrencyCodes
        );
        actions._setTrade(enrichedResult);
        return {
          isSuccessful: true,
          enrichedResult,
        };
      } catch (error) {
        const errorCode = getApiErrorCode(error);
        const message = getApiErrorMessage(error);

        actions._setErrorCode(errorCode);
        actions.setError(message); // this sets the error that rate has expired

        return {
          isSuccessful: false,
          errorMessage: message,
          errorCode,
        };
      } finally {
        actions.setBusy(false);
      }
    }
  ),
  // Listeners
  _resetErrorCode: actionOn(
    actions => actions.setError,
    (state, { payload }) => {
      if (payload === null) {
        state._errorCode = null;
      }
    }
  ),
};
