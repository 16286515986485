import { FC, PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import {
  AppIcon,
  AppIconType,
  BuildEnv,
  ChartSeriesType,
  ChartTimeRange,
  getRuntimeEnv,
  PriceCell,
  TradingViewChartData,
  useEnvironmentConfig,
} from 'common';
import cx from 'classnames';
import tvChartHtml from 'common/src/utils/charting/tv-chart.html';
import { SegmentedControl } from '../lib/segemented-control';
import { IS_LOCAL_DEV } from '~/utils/is-local-dev';

interface ChartContainerProps extends PropsWithChildren {
  isErrored: boolean;
  errorMessage: string;
  containerHeight?: number;
}

const ChartContainer: FC<ChartContainerProps> = ({
  children,
  isErrored,
  errorMessage,
}) => {
  /**
   * DOM
   */
  return (
    <div className="relative">
      {isErrored ? (
        <div className="flex items-center justify-center h-10 text-sm text-primary bg-gray-100">
          {errorMessage}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

type ChartInnerProps = Pick<Props, 'data'> & {
  title: string;
  containerHeight?: number;
};

const ChartInner: FC<ChartInnerProps> = ({ data, title, containerHeight }) => {
  /**
   * Vars
   */
  const ref = useRef<HTMLIFrameElement | null>(null);
  const { REACT_APP_BUILD_ENV } = useEnvironmentConfig();
  const { isProd, isLowerRegionEnv } = getRuntimeEnv(
    REACT_APP_BUILD_ENV as BuildEnv
  );

  /**
   * Methods
   */
  const updateChartData = useCallback(() => {
    if (!ref || !ref.current || !ref.current.contentWindow) {
      return;
    }
    if (!data || data.errored) {
      return;
    }
    const originParser = /^(https?:\/\/[^\\/]+)/;
    const iframeOrigin =
      ref.current.src.match(originParser)?.[1] || location.origin;
    ref.current.contentWindow.postMessage(JSON.stringify(data), iframeOrigin);
    ref.current.contentWindow.focus();
  }, [data, ref]);

  /**
   * Hooks
   */
  useEffect(() => {
    updateChartData();
  }, [data]);

  /**
   * DOM
   */
  const iframeProps = {
    ref,
    title,
    width: `100%`,
    height: containerHeight,
    style: {
      padding: 0,
      margin: 0,
    },
    src: isProd
      ? 'https://prod-stablehouse-assets.s3.eu-west-1.amazonaws.com/charting/tv-chart.html'
      : isLowerRegionEnv && !IS_LOCAL_DEV
        ? 'https://stablehouse-assets.s3.eu-west-1.amazonaws.com/charting/tv-chart.html'
        : undefined,
  };
  return (
    <iframe
      {...iframeProps}
      srcDoc={IS_LOCAL_DEV ? tvChartHtml : undefined}
      onLoad={updateChartData}
    />
  );
};

interface Props {
  disabled: boolean;
  percentageChange?: number;
  data: TradingViewChartData | null;
  timeRanges: ChartTimeRange[];
  seriesTypes: ChartSeriesType[];
  onChange: (
    timeRange: ChartTimeRange | undefined,
    seriesType: ChartSeriesType | undefined,
    currencyCode?: string
  ) => void;
  containerHeight?: number;
}

export const TradingViewChart: FC<Props> = ({
  disabled,
  onChange,
  data,
  timeRanges,
  seriesTypes,
  percentageChange,
  containerHeight,
}) => {
  /**
   * Methods
   */
  const onTimeRangeChanged = useCallback(
    (value: ChartTimeRange) => {
      if (!data || !value) {
        return;
      }
      if (data.timeRange === value) {
        return;
      }
      onChange(value, data.seriesType, data.currencyCode);
    },
    [onChange, data]
  );

  const onSeriesTypeChanged = useCallback(
    (value: ChartSeriesType) => {
      if (!data || !value) {
        return;
      }
      if (data.seriesType === value) {
        return;
      }
      onChange(data.timeRange, value, data.currencyCode);
    },
    [data, onChange]
  );

  /**
   * DOM
   */
  const hasSeries = !!(data?.seriesType && seriesTypes.length > 1);
  return (
    <>
      <ChartContainer
        isErrored={!!data?.errored}
        errorMessage={`No charting data available`}
      >
        {!!percentageChange && (
          <div className="px-4 md:px-8">
            <PriceCell
              prefix={
                data?.timeRange ? <>{data?.timeRange}:&nbsp;</> : undefined
              }
              value={Number(percentageChange)}
            />
          </div>
        )}
        <div
          className={cx('flex text-primary px-6 py-3', {
            'justify-between': hasSeries,
            'justify-end': !hasSeries,
          })}
        >
          {data?.timeRange && (
            <SegmentedControl<ChartTimeRange>
              disabled={disabled}
              type="button"
              itemPadding="px-1 py-2"
              containerPadding=""
              item={data?.timeRange}
              items={timeRanges}
              itemTemplate={item => (
                <span
                  className={cx(
                    'capitalize font-semibold typo-b5 w-6 text-center',
                    { 'opacity-40': disabled }
                  )}
                >
                  {item}
                </span>
              )}
              onSelected={onTimeRangeChanged}
            />
          )}
          {hasSeries && (
            <SegmentedControl<ChartSeriesType>
              type="button"
              itemPadding="px-1.5 py-1"
              disabled={disabled}
              item={data.seriesType as ChartSeriesType}
              items={seriesTypes}
              itemTemplate={item => {
                const icon = `graph-${item.toLowerCase()}` as AppIconType;
                return (
                  <AppIcon
                    size={22}
                    cls="rounded-4"
                    icon={icon}
                    bg={'bg-transparent'}
                  />
                );
              }}
              onSelected={onSeriesTypeChanged}
            />
          )}
        </div>
        <ChartInner
          data={data}
          title={`${data?.currencyCode} pricing data`}
          containerHeight={containerHeight}
        />
      </ChartContainer>
    </>
  );
};
