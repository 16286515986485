import {
  ActionId,
  Enriched,
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
  EnrichedAssetHoldings,
  EnrichedCurrencyInformation,
} from '../types';

export function getActionButtonCapabilities(
  allowedActions: ActionId[],
  currency: EnrichedCurrencyInformation,
  asset?: EnrichedAccountDetailAsset | EnrichedAssetHolding,
  assetHoldings?: EnrichedAssetHoldings,
  isClient?: boolean,
  accounts?: Enriched.ListAccountItem[]
) {
  const isCrypto =
    !currency || (!currency.isAssetOfTypeFiat && !currency.isAssetOfTypeFund);
  const isNotFund = asset
    ? !asset?.currency.isAssetOfTypeFund
    : assetHoldings && assetHoldings.currency.isAssetOfTypeFund;
  const isFiat = asset
    ? asset.currency.isAssetOfTypeFiat
    : assetHoldings?.currency.isAssetOfTypeFiat;
  const hasBalance = asset ? asset.hasBalance : assetHoldings?.hasBalance;

  const canBuy = allowedActions.includes(ActionId.Buy);
  const canSell = allowedActions.includes(ActionId.Sell);
  const canSend =
    allowedActions.includes(ActionId.Send) &&
    (isCrypto ||
      ((asset ? asset.canSendCrypto || asset.canStabletagTransfer : true) &&
        hasBalance &&
        !isFiat &&
        isNotFund));
  const canReceive =
    allowedActions.includes(ActionId.Receive) &&
    (isCrypto ||
      ((asset ? !isClient && asset.canReceiveCrypto : true) &&
        !isFiat &&
        isNotFund));
  const canTransfer =
    allowedActions.includes(ActionId.Transfer) ||
    (asset?.canSendAccountTransfer && !!accounts?.length); // length > 1
  const canAddCash =
    allowedActions.includes(ActionId.AddCash) &&
    (!currency ||
      currency?.isAssetOfTypeFiat ||
      (asset?.canReceiveCash && isFiat));
  const canWithdrawCash =
    allowedActions.includes(ActionId.WithdrawCash) &&
    (!currency ||
      currency?.isAssetOfTypeFiat ||
      (asset?.canSendCash && hasBalance && isFiat));

  const canView =
    !(
      canBuy ||
      canSell ||
      canSend ||
      canReceive ||
      canAddCash ||
      canTransfer ||
      canWithdrawCash
    ) || isNotFund;

  return {
    canView,
    canBuy,
    canSell,
    canSend,
    canReceive,
    canAddCash,
    canTransfer,
    canWithdrawCash,
  };
}
