import { FC, memo, useCallback, useMemo, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import ReactCountdownClock from 'react-countdown-clock';
import {
  AppDialog,
  FormHeader,
  Note,
  NumberInput,
  SelectInput,
  TIMERS,
  TradeFormValues,
  useAnimationEffect,
  useWorkflowBuySellForm,
} from 'common';
import PulseLoader from 'react-spinners/PulseLoader';
import { AppStore, DataStore } from '~/store';
import { getAccountInfo, getAccountType } from '~/utils/get-account-details';
import { TRADE_STEPS } from './steps';
import { NotAllowed } from '../shared/not-allowed';
import { API } from '@xbto/api-client';
import {
  FieldWithLabel,
  ImitationSelectField,
  SegmentedControl,
} from '~/components/lib';
import { enrichedAssetItemTemplate } from '~/components/app-selector/templates';
import { AssetBalanceBreakdown } from '../shared/asset-balance-breakdown';
import { AppTooltip } from '~/components/app-tooltip';
import { FormFooter } from '../shared/form-footer';
import cx from 'classnames';
import { Alert, CalloutTradeExpired } from '@xbto/universal-components';

const ZeroBalanceNote = () => {
  return (
    <p className="text-xs text-failure ml-1 font-bold">
      You don&apos;t have enough balance to proceed with this transaction
    </p>
  );
};

const TradeSimulationNote: FC = () => {
  /**
   * Store
   */
  const labels = DataStore.useStoreState(s => s.buySell.labels);
  const note = labels.simulationNote;

  /**
   * DOM
   */
  if (!note) {
    return null;
  }
  return (
    <Note
      cls="flex flex-row items-center text-sm justify-between"
      textSize="sm"
    >
      <p className="inline-flex gap-1">
        <span className="text-grey-darker">{note.title}:</span>
        <span className="text-primary font-bold">{note.description}</span>
        {note.value && <span className="text-grey-darker">({note.value})</span>}
      </p>
      <AppTooltip
        effect="float"
        content={<div className="my-1">{note.disclaimer}</div>}
      />
    </Note>
  );
};

export const Form: FC<Partial<StepWizardChildProps>> = memo(
  ({ goToNamedStep }) => {
    if (!goToNamedStep) {
      console.error(
        `Component Trade.Form can only be used as a child of StepWizard.`
      );
      return null;
    }

    /**
     * Store
     */
    const sourceAssetBalances = DataStore.useStoreState(
      s => s.buySell.sourceAssetBalances
    );
    const destinationAssetBalances = DataStore.useStoreState(
      s => s.buySell.destinationAssetBalances
    );
    const setSelectedDialogType = AppStore.useStoreActions(
      a => a.setDashboardSelectedDialogType
    );
    const setSelectedCurrencyCode = AppStore.useStoreActions(
      a => a.setSelectedCurrencyCode
    );
    const error = DataStore.useStoreState(s => s.buySell.error);
    const formValues = DataStore.useStoreState(s => s.buySell.formValues);
    const labels = DataStore.useStoreState(s => s.buySell.labels);
    const accountDetail = DataStore.useStoreState(
      s => s.portfolio.accountDetail
    );
    const simulation = DataStore.useStoreState(s => s.buySell.simulation);
    const canTradeFromAccount = DataStore.useStoreState(
      s => s.buySell.canTradeFromAccount
    );
    const createTrade = DataStore.useStoreActions(a => a.buySell.createTrade);

    /**
     * State
     */
    const [isTradeExpiredInfoOpen, setTradeExpiredInfoOpen] = useState(false);

    /**
     * Hooks
     */
    const {
      isBuy,
      isZeroBalance,
      isRateExpiredError,
      showDisclaimerExecutionTime,
      fromAssetCurrency,
      toAssetCurrency,
      amountAsString,
      isAmountDisabled,
      isConfirmDisabled,
      isMaxEnabled,
      amountDecimals,
      isResimulating,
      canResimulate,
      onFromAsset,
      onToAsset,
      onMax,
      onSide,
      onAmount,
      onAmountSide,
      onResimulate,
      onCreate,
      title,
    } = useWorkflowBuySellForm({
      DataStore,
      goToAssetList: () => {
        goToNamedStep(TRADE_STEPS.CHOOSE_ASSETS);
      },
    });

    /**
     * Methods
     */
    const handleSideChanged = useCallback(
      (newSide: API.Side) => {
        setSelectedCurrencyCode(null);
        onSide(newSide);
      },
      [setSelectedCurrencyCode, onSide]
    );

    const handleCloseTradeExpiredInfo = useCallback(() => {
      setTradeExpiredInfoOpen(false);
    }, []);

    const handleSubmit = useCallback(async () => {
      const isSuccessful = await onCreate();
      if (!isSuccessful) {
        // stay in page and let user decide
        return;
      }
      goToNamedStep(TRADE_STEPS.COMPLETED_STATUS);
    }, [goToNamedStep, simulation, formValues, createTrade]);

    const amountSideValues = useMemo(
      () =>
        [
          formValues.fromAsset && {
            label: formValues.fromAsset.currency.displayCode,
            value: 'fromAssetAmount',
          },
          formValues.toAsset && {
            label: formValues.toAsset.currency.displayCode,
            value: 'toAssetAmount',
          },
        ].filter(it => !!it),
      [formValues.fromAsset, formValues.toAsset]
    );

    // Effects
    useAnimationEffect(() => {
      setTradeExpiredInfoOpen(isRateExpiredError);
    }, [isRateExpiredError]);

    /**
     * DOM
     */
    if (!canTradeFromAccount) {
      return (
        <NotAllowed
          message={`There are no assets in your account ${getAccountType(
            accountDetail?.account
          )} ${
            accountDetail?.account?.accountNumber
          } to perform this operation`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }
    if (!accountDetail || !formValues?.side) {
      /**
       * Note:
       * Cannot get to this form
       * - if you are not inside an account
       * - or have not chosen a side for trade
       */
      return null;
    }
    return (
      <div className="flex flex-col">
        {/* header  */}
        <FormHeader
          cls="mb-4"
          title={title}
          accountInfo={getAccountInfo(accountDetail.account, 'From')}
        />

        <div className="flex flex-col gap-y-6 px-6 md:px-10">
          {/* buy/ sell selector  */}
          <SegmentedControl<API.Side>
            item={formValues.side}
            items={[API.Side.Buy, API.Side.Sell]}
            onSelected={handleSideChanged}
          />

          {/* currencies fields  */}
          {/* asset - buy/ sell */}
          <div className="flex flex-col flex-1 gap-y-1">
            <ImitationSelectField
              label={formValues.side}
              onClick={() => {
                setSelectedCurrencyCode(null);
                onFromAsset();
              }}
            >
              {enrichedAssetItemTemplate(
                formValues.fromAsset || undefined,
                undefined,
                undefined,
                `Choose asset to ${isBuy ? 'buy' : 'sell'}`
              )}
            </ImitationSelectField>
            {!isBuy && !!sourceAssetBalances && (
              <>
                <AssetBalanceBreakdown
                  breakdownType="pendingIncoming"
                  availableToTitle={`Available to sell`}
                  balances={sourceAssetBalances}
                />
                {isZeroBalance && <ZeroBalanceNote />}
              </>
            )}
          </div>
          {/* asset - to */}
          <div className="flex flex-col flex-1 gap-y-1">
            <ImitationSelectField
              label={isBuy ? 'Pay with' : 'To'}
              onClick={onToAsset}
              disabled={!formValues.fromAsset}
            >
              {enrichedAssetItemTemplate(
                formValues.toAsset || undefined,
                undefined,
                undefined,
                `Choose asset to ${isBuy ? 'sell' : 'receive'}`
              )}
            </ImitationSelectField>
            {isBuy && !!destinationAssetBalances && (
              <>
                <AssetBalanceBreakdown
                  breakdownType="pendingIncoming"
                  availableToTitle="Available to sell"
                  balances={destinationAssetBalances}
                />
                {isZeroBalance && <ZeroBalanceNote />}
              </>
            )}
          </div>
          {/* quantity  */}
          <div className="flex flex-col flex-1 gap-y-1">
            <FieldWithLabel label="Quantity">
              <NumberInput
                placeholder={'Enter amount'}
                value={amountAsString || ''}
                allowNegative={false}
                decimalScale={amountDecimals}
                disabled={isAmountDisabled}
                autoComplete="off"
                allowLeadingZeros={false}
                leftAddonCls="px-0 no-addon-border"
                leftAddon={
                  formValues.fromAsset ? (
                    <SelectInput
                      asAddon
                      contentCls="p-4"
                      optionsCls="min-w-min whitespace-nowrap"
                      value={formValues.amountSide || ''}
                      disabled={isAmountDisabled}
                      onChange={val => {
                        onAmountSide(val as TradeFormValues['amountSide']);
                      }}
                      values={amountSideValues}
                      getItemValue={it => {
                        if (!it) {
                          return null;
                        }
                        return it.value;
                      }}
                      getSelectedItem={val => {
                        if (!val) {
                          return null;
                        }
                        return amountSideValues.find(it => it?.value === val);
                      }}
                      getSelectedItemTemplate={it => {
                        if (!it) {
                          return null;
                        }
                        return (
                          <span className="text-base font-bold w-auto">
                            {it.label}
                          </span>
                        );
                      }}
                      getItemTemplate={it => {
                        if (!it) {
                          return null;
                        }
                        const isLast =
                          amountSideValues.length - 1 ===
                          amountSideValues.findIndex(
                            item => it.value === item?.value
                          );
                        return (
                          <span
                            className={cx(
                              'text-base font-bold block p-4 hover-bg-grey-brighter whitespace-nowrap',
                              isLast ? 'border-0' : 'border-b'
                            )}
                          >
                            {it.label}
                          </span>
                        );
                      }}
                    />
                  ) : undefined
                }
                rightAddon={
                  isMaxEnabled ? (
                    <button
                      type="button"
                      disabled={isAmountDisabled}
                      data-testid="max-button"
                      className="text-sm text-primary font-bold underline focus:outline-none relative mb-1"
                      onClick={onMax}
                    >
                      Max
                    </button>
                  ) : undefined
                }
                onChange={onAmount}
              />
            </FieldWithLabel>
            {!!fromAssetCurrency && !!toAssetCurrency && (
              <TradeSimulationNote />
            )}
          </div>

          {!!showDisclaimerExecutionTime && (
            <Alert
              mt="s"
              icon="lock-clock"
              title={labels.disclaimerExecutionTime.title}
              message={labels.disclaimerExecutionTime.description}
              variant="warning"
            />
          )}
        </div>

        {/* actions  */}
        <FormFooter
          error={isRateExpiredError ? null : error}
          isSubmitDisabled={isConfirmDisabled}
          submitText={labels.confirmButtonText}
          onSubmit={handleSubmit}
          className={'mt-10'}
        >
          {/* conditionally show rate/ price  */}
          {!!simulation && (
            <p className="flex flex-row justify-between text-sm mb-4 items-center">
              <div>
                <div className="flex flex-row items-center gap-1">
                  <span className="text-sm text-grey-darker">
                    {labels.exchangeRateTitle}
                  </span>
                  <AppTooltip
                    effect="float"
                    content={
                      <div className="my-1 flex flex-col gap-y-6.5">
                        <span className="text-left">
                          {labels.rateConfirmation}
                        </span>
                        <span className="text-left">
                          <b className="text-grey-darker mr-0.5">
                            {labels.slipageRisk.title}:
                          </b>{' '}
                          {labels.slipageRisk.description}
                        </span>
                      </div>
                    }
                  />
                </div>
                <div className="mt-2 font-bold text-primary flex flex-col">
                  <span>{simulation.formatted.rateToWithCurrencyCode}</span>
                  <span>{simulation.formatted.rateFromWithCurrencyCode}</span>
                </div>
              </div>
              {/* TODO: came up with this during pairing with Dipen, I have a note to make tickets to embrace this library in web-app */}
              {isResimulating && <PulseLoader size={5} color="#3eb37d" />}
              {canResimulate && (
                <ReactCountdownClock
                  seconds={TIMERS.AUTO_RESIMULATE_IN_SECONDS}
                  color="#3eb37d"
                  size={28}
                  weight={3}
                  fontSize={14}
                  showMilliseconds={false}
                  onComplete={onResimulate}
                />
              )}
            </p>
          )}
        </FormFooter>
        {/* Dialog must be out of the DOM when not displayed, otherwise,
         ESC does not work on parent/wrapper Dialog
         */}
        {isTradeExpiredInfoOpen && (
          <AppDialog
            isOpen={isTradeExpiredInfoOpen}
            onClose={handleCloseTradeExpiredInfo}
            widthCls="max-w-md"
          >
            <CalloutTradeExpired onClose={handleCloseTradeExpiredInfo} />
          </AppDialog>
        )}
      </div>
    );
  }
);
