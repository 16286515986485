import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { AmountChangePercent, AmountPrice } from '../../organisms';
import { Col } from '../../atoms';
export const MarketListItemAddonPrice = memo(({ data }) => {
    return (_jsxs(Col.R, { children: [_jsx(AmountPrice, { em: true, value: data.price }), _jsx(Col, Object.assign({ style: $.space }, { children: _jsx(AmountChangePercent, { value: data.change24hPercent }) }))] }));
});
const $ = StyleSheet.create({
    space: {
        marginTop: 2,
    },
});
