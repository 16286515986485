import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StyleSheet } from 'react-native';
import { API } from '@xbto/api-client';
import { theme } from '@xbto/design-system';
import { Col, RadioBox, Row, TextBody } from '../../atoms';
import { Hypertext, Icon } from '../../molecules';
import { _KytWrapper } from './_kyt-wrapper';
import { sizes } from '../../theme';
export function KytPlatformCategory({ onNext }) {
    /**
     * DOM
     */
    return (_jsxs(_KytWrapper, Object.assign({ title: "Where is this crypto going?" }, { children: [_jsxs(Col, Object.assign({ style: $.content }, { children: [_jsx(RadioBox, { title: "Centralised platform", label: "An exchange or hosted wallet that holds the keys on your behalf (e.g. Coinbase, Binance, etc.)", onPress: onNext, value: API.TravelRuleWalletType.Centralized }), _jsx(RadioBox, { title: "Decentralised platform", label: "A self-hosted wallet or address where you hold the private keys.", onPress: onNext, value: API.TravelRuleWalletType.Decentralized })] })), _jsx(Col.C, { children: _jsx(Hypertext, Object.assign({ href: "https://helpcenter.xbto.com/xbto-help_centre/travel-rule-updates-to-crypto-transfer-procedures", hrefAttrs: { target: '_blank' } }, { children: _jsxs(Row.LR, Object.assign({ style: $.link }, { children: [_jsx(TextBody, { children: "Why do we need this information?" }), _jsx(Icon, { name: "arrow-outward", size: "sm" })] })) })) })] })));
}
const $ = StyleSheet.create({
    content: {
        marginBottom: sizes.xxl,
        rowGap: sizes.xs,
    },
    link: {
        columnGap: theme.spacing['0.5'],
    },
});
