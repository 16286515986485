import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Col, ListItem, Row, TextBody, TextSubheading } from '../../atoms';
import { ApyLabel } from '../../organisms';
export const _MarketListItem = memo(({ addon, apy, coinIcon, displayCode, footer, id, onPress, subtitle, trending = false, }) => {
    let subtitleEl = null;
    if (typeof subtitle === 'string') {
        subtitleEl = _jsx(TextBody, { children: subtitle });
    }
    else if (subtitle) {
        subtitleEl = subtitle;
    }
    return (_jsx(ListItem, Object.assign({ id: id, onPress: onPress }, { children: _jsxs(Row.LRT, { children: [coinIcon && (_jsx(Col, Object.assign({ mt: "xxs", mr: "s" }, { children: coinIcon }))), _jsxs(Col, Object.assign({ style: $.content }, { children: [_jsxs(Row.LR, { children: [_jsxs(Col, { children: [_jsxs(Row.L, { children: [_jsx(TextSubheading, { children: displayCode }), !!apy && apy > 0 && (_jsx(Col, Object.assign({ ml: "xxs" }, { children: _jsx(ApyLabel, { value: apy }) }))), !!trending && _jsx(TextSubheading, Object.assign({ ml: "xxs" }, { children: "\uD83D\uDD25" }))] }), !!subtitleEl && _jsx(Col, Object.assign({ style: $.subtitle }, { children: subtitleEl }))] }), !!addon && addon] }), !!footer && footer] }))] }) })));
});
const $ = StyleSheet.create({
    content: {
        flex: 1,
    },
    subtitle: {
        marginTop: 2,
    },
});
